<template>
  <section id="hero-alt">
    <v-img
      :height="$vuetify.breakpoint.mdAndUp ? 350:''"
      :gradient="gradient"
      :src="src"
      color="#45516b"
      flat
      max-width="100%"
      tile
    >
      <v-row v-if="title" align="center" class="ma-0 fill-height text-center" justify="center">
        <v-col cols="6">
          <v-img src="@/assets/images/music-landing/heroimg@2x.png"></v-img>
        </v-col>
        <v-col cols="6">
          <v-img src="@/assets/images/music-landing/heroimg@2x.png"></v-img>
        </v-col>
      </v-row>
    </v-img>
  </section>
</template>

<script>
// Components
import { HexToRGBA, RGBAtoCSS } from "vuetify/lib/util/colorUtils";

export default {
  name: "SectionHeroAlt",

  metaInfo() {
    return {
      changed: (meta) => (this.title = meta.titleChunk.toUpperCase()),
    };
  },

  provide: {
    heading: { align: "center" },
  },

  data: () => ({
    title: "",
  }),

  computed: {
    gradient() {
      const color = `${this.$vuetify.theme.themes.light.success}CC`;
      const overlay = RGBAtoCSS(HexToRGBA(color));

      return `to top, ${overlay}, ${overlay}`;
    },
    src() {
      return "https://picsum.photos/1200/500";
    },
    items() {
      return [{ text: "HOME", to: "/" }, { text: this.title }];
    },
  },
};
</script>

<style lang="sass">
#hero-alt
  .v-breadcrumbs__item
    color: #FFFFFF
</style>
